<template>
  <div style="text-align: center;">
    <div class="header">Product Service System</div>
    <div
      class="login"
      v-bind:style="{
        marginLeft: marginLeft + 'px',
        marginTop: marginTop + 'px',
      }"
    >
      <el-input
        placeholder="Username"
        class="input"
        style="margin-top: 100px;"
        v-model="username"
        clearable
      ></el-input>
      <el-input
        placeholder="Password"
        class="input"
        show-password
        clearable
        style="margin-top: 20px;"
        v-model="password"
      ></el-input>
      <br />
      <el-button
        type="primary"
        style="margin-top: 20px; float:left; margin-left: 100px; width: 300px;"
        @click="login"
        >Login</el-button
      >
      <br />
      <el-link
        type="primary"
        style="float:left; margin-left:100px; margin-top:20px;"
        >Forget password?</el-link
      >
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "./Footer.vue";
import http from "../utils/http";
export default {
  name: "Login",
  components: {
    Footer,
  },
  data() {
    return {
      marginLeft: 0,
      marginTop: 0,
      username: "",
      password: "",
    };
  },
  created() {
    this.marginLeft = (window.innerWidth - 500) / 2;
    this.marginTop = (window.innerHeight - 100 - 500) / 2;
  },
  mounted() {},
  methods: {
    login() {
      var _this = this;
      http
        .post("/login", { username: this.username, password: this.password })
        .then((res) => {
          if (res.data.status === 1) {
            localStorage.setItem("pstoken", res.data.data.token);
            _this.$message({
              type: "success",
              message: "Login successfully!",
            });
            let toPath = this.$route.query.redirect;
            if (toPath == null) {
              toPath = "/";
            }
            _this.$router.replace(toPath).catch(() => {});
          } else {
            _this.$message.error(res.data.message);
          }
        });
    },
  },
};
</script>
<style scoped>
.header {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #15375a;
  font-size: 16px;
  color: white;
  /* font-weight: bold; */
}
.login {
  width: 500px;
  height: 500px;
  background: #f2f6fc;
}
.input {
  width: 300px;
}
</style>
